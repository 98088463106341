import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import MainContext from '../../Configs/Context/MainContext'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormGroup, Button, Form } from 'react-bootstrap'
import { AdminTransactionAPI } from '../../Configs/Utils/Apis/UserApis'
import Pagination from '../../Configs/Pagination/Pagination';

const AdminDashboard = () => {

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [prevSearch, setPrevSearch] = useState();
  const [adminDashboardTransactionsData, setAdminDashboardTransactionsData] = useState([]);
  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const AdminTransaction = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await AdminTransactionAPI(startDate, endDate, search, currentPage, limit, controller)
    if (response.status === 200) {
      setAdminDashboardTransactionsData(response.data?.data)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      AdminTransaction()
    }
  }, [startDate, endDate, search, currentPage, limit])

  const handleSearch = (e) => {
    e.preventDefault();
    AdminTransaction()
    setCurrentPage(1)
    setPrevSearch({ startDate, endDate, search });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    setSearch('')
    AdminTransaction()
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);


  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Wallet Address copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className='page-wrapper p-3 back-page'>
      <div className='page-content'>
        <div className='card w-100 mt-3 mb-3'>
          <div className='card-body'>
            <div>
              <form className='gap-2 mb-3' onSubmit={handleSearch}>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div>
                    {/* <FormGroup>
                      <Form.Control type='text' placeholder='Enter Name' onChange={(e) => setSearch(e.target.value)} value={search} className='search-input text-white form-control' />
                    </FormGroup> */}
                    <FormGroup>
                      <Form.Select style={{
                        backgroundColor: 'black',
                        color: 'white',
                        '--bs-form-select-bg-img': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='white' d='M2 5L0 0h4z'/%3E%3C/svg%3E")`,
                        '--bs-form-select-bg-size': '10px',
                      }} className='status-select' value={search} onChange={(e) => setSearch(e.target.value)}>
                        {/* <option value=''>Select Type</option> */}
                        <option value=''>All</option>
                        <option value='withdraw'>Withdraw</option>
                        <option value='By_Admin'>Deposit</option>
                        <option value='Referral_Reward'>Referral Reward</option>
                        <option value='Direct_Reward'>Staking Reward</option>
                      </Form.Select>
                    </FormGroup>
                  </div>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleDateChange}
                        value={dateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  <Button variant="secondary" onClick={handleResetFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-modern table-hover mb-0 transaction-table">
                <thead style={{ backgroundColor: '#0B1830' }}>
                  <tr>
                    <th scope="col" className='text-white'>No.</th>
                    <th scope="col" className='text-white'>Name</th>
                    <th scope="col" className='text-white'>Type</th>
                    <th scope="col" className='text-white'>Amount</th>
                    <th scope="col" className='text-white'>Wallet id</th>
                    <th scope="col" className='text-white text-end'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading && adminDashboardTransactionsData?.length > 0 ? (
                      adminDashboardTransactionsData.map((item, index) => {
                        let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                        let status;
                        if (item.status === 0) {
                          status = 'Pending'
                        } else if (item.status === 1) {
                          status = 'Complete'
                        } else if (item.status === 2) {
                          status = "Reject"
                        }

                        let transactionType;
                        if (item.name === 'Withdraw') {
                          transactionType = 'Withdraw'
                        } else if (item.name === 'By_Admin') {
                          transactionType = 'Deposit'
                        } else if (item.name === 'Direct_Reward') {
                          transactionType = "Staking Reward"
                        } else if (item.name === 'Referral_Reward') {
                          transactionType = "Referral Reward"
                        }
                        return (
                          <>
                            <tr key={index}>
                              <td className='text-white'>{index + 1}</td>
                              <td className='text-white'>{item?.user?.name}</td>
                              <td className='text-white'>{transactionType}</td>
                              {/* <td className='text-white'>{item.amount}</td> */}
                              <td className='text-white'>{item.name === "Referral_Reward" || item.name === 'Direct_Reward' ? item.rewardAmount : item.amount}</td>
                              <td className='text-white'>
                                <div className='d-flex justify-content-center'>
                                  <div>{item?.user?.walletAddress}</div>
                                  <div style={{ cursor: 'pointer' }} class=" cursor-pointer ms-2" onClick={() => handleCopy(`${item?.user?.walletAddress}`)}><i class="fa-regular fa-copy text-danger"></i></div>
                                </div>
                              </td>
                              <td className='text-white text-end'>{formattedTime}</td>
                            </tr>
                          </>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center text-white">
                          {loading ?
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            : "No data found"}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <div className='centerListPagination mt-2'>
              {!loading && totalPage > 1 &&
                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard