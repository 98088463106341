import React, { useContext, useEffect, useState } from 'react'
import Vector from '../../Assets/Images/Vector.png'
import { getReferralTransactionAPI, getReferralLinkSharableAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment'
import MainContext from '../../Configs/Context/MainContext'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormGroup, Button } from 'react-bootstrap'
import ROUTES from '../../Configs/Routes'
import Pagination from '../../Configs/Pagination/Pagination'

const IMG_PREFIX = process.env.REACT_APP_SERVER_URL;

const ReferralPage = () => {

  const [activeButton, setActiveButton] = useState('request');
  const [referralCode, setReferralCode] = useState('');
  const controllers = []
  const [loading, setLoading] = useState(false);
  const [referralTransactionsData, setReferralTransactionsData] = useState([]);
  const [totalReferralRewardAmount, setTotalReferralRewardAmount] = useState('');
  const context = useContext(MainContext)

  const location = window.location.origin;

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);
  const [prevSearch, setPrevSearch] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');
  const [totalRecord, setTotalRecord] = useState();
  const [referralLinkSharableStatus, setReferralLinkSharableStatus] = useState();

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const getReferralLinkSharable = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getReferralLinkSharableAPI(controller)
    if (response.status === 200) {
      setReferralLinkSharableStatus(response.data?.isReferralLinkSharable)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getReferralLinkSharable()
  }, [])

  const getReferralTransaction = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getReferralTransactionAPI(startDate, endDate, currentPage, limit, controller)
    if (response.status === 200) {
      setReferralTransactionsData(response.data?.data)
      setTotalReferralRewardAmount(response.data.totalRewardAmount)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      getReferralTransaction()
    }
  }, [startDate, endDate, currentPage, limit])

  const handleSearch = (e) => {
    e.preventDefault();
    getReferralTransaction()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    getReferralTransaction()
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Referral Code copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  const handleCopyWalletAddress = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('URL copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className="page-wrapper p-3 back-page">
      <div className='page-content'>
        <div className='row'>
          <div className='col-12 col-lg-9 col-xl-9'>
            <div className='card w-100'>
              <div className='card-body'>
                <div className='d-flex gap-3 withdrawal-btn-group'>
                  <button className={`${activeButton === 'request' ? 'withdrawal-active-btn' : ''}`}
                    onClick={() => setActiveButton('request')}>
                    Referral Request
                  </button>
                  <button className={`${activeButton === 'history' ? 'withdrawal-active-btn' : ''}`}
                    onClick={() => setActiveButton('history')}>
                    Referral History
                  </button>
                </div>

                <div className='mt-4'>
                  {
                    activeButton === "request" &&
                    <div class="row d-flex justify-content-center align-items-center">

                      {
                        referralLinkSharableStatus === true ?
                          <>
                            <div class="col-md-5 text-white text-end referral-heading">
                              <label>Referral Code :</label>
                            </div>
                            <div class="col-md-6">
                              {/* <div class="d-flex align-items-center gap-2">
                                <div>
                                  <span className="form-control text-white">{`https://fronxtoken.com/user-referral/${context.profile?.referralCode}`}</span>
                                </div>
                                <div style={{ cursor: 'pointer' }} class=" cursor-pointer" onClick={() => handleCopy(`https://fronxtoken.com/user-referral/${context.profile.referralCode}`)}><i class="fa-regular fa-copy text-danger"></i></div>
                              </div> */}

                              <div class="d-flex align-items-center gap-2">
                                <div>
                                  <span className="form-control text-white">{`${location}/user-referral/${context.profile?.referralCode}`}</span>
                                </div>
                                <div style={{ cursor: 'pointer' }} class=" cursor-pointer" onClick={() => handleCopy(`${location}/user-referral/${context.profile.referralCode}`)}><i class="fa-regular fa-copy text-danger"></i></div>
                              </div>
                            </div>
                            <p className='text-white mt-2'>
                              Note :
                              <ul>
                                <li>You share your referral code to anyone then you will get {context?.userSetting?.referralRewardPercentage}% of every staking reward of your referral user.</li>
                                <li>There are not any referral user limit, you can refer as many as you like and get more Fronx Token.</li>
                              </ul>
                            </p>
                            {/* <div className='text-white'>
                              <p>note :</p>
                              <ul>
                                <li>
                                  You can share your <strong>referral code</strong> with anyone. You will earn
                                  <strong>25%</strong> of every direct reward earned by your referral users.
                                </li>
                                <li>
                                  There is <strong>no limit</strong> to the number of referral users. Refer as many people as you like and earn more
                                  <strong>Fronx tokens</strong>.
                                </li>
                              </ul>
                            </div> */}
                          </>
                          : <p className='text-white text-center fs-5'>You have not made any deposit yet</p>
                      }

                      <div className='col-md-5'></div>
                      {/* <div class="col-md-6">
                        <div class="mt-4">
                          <button type="button" class="withdrawal-active-btn w-50" style={{ padding: '7px 40px' }}>Invitation</button>
                        </div>
                      </div> */}
                    </div>
                  }

                  {
                    activeButton === 'history' &&
                    <>
                      <div className="">
                        <div className=''>
                          {/* <div>
                            <form className='d-flex flex-wrap justify-content-between gap-2 mb-3' onSubmit={handleSearch}>
                              <div className='text-white'>
                                <p>Total Referral Reward: {totalReferralRewardAmount} Fronx</p>
                              </div>
                              <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                                <div className='dashDateSearch'>
                                  <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                                  <FormGroup>
                                    <DateRangePicker
                                      className='dateSearch'
                                      onChange={handleDateChange}
                                      value={dateRange}
                                      showLeadingZeros
                                      format="dd/MM/y"
                                    />
                                  </FormGroup>
                                </div>
                                <Button variant="secondary" onClick={handleResetFilter}>
                                  <i class="fa-solid fa-retweet"></i>
                                </Button>
                              </div>
                            </form>
                          </div> */}
                          <div className="table-responsive">
                            <table className="table mb-0 table-modern table-hover mb-0 transaction-table">
                              <thead style={{ backgroundColor: '#0B1830' }}>
                                <tr>
                                  {/* <th scope="col" className='text-white'>No.</th>
                                  <th scope="col" className='text-white'>Date</th>
                                  <th scope="col" className='text-white'>Staking Amount</th>
                                  <th scope="col" className='text-white'>Staking Reward</th>
                                  <th scope="col" className='text-white  text-end'>Referral Reward</th> */}
                                  <th scope="col" className='text-white'>No.</th>
                                  <th scope="col" className='text-white'>User Name</th>
                                  <th scope="col" className='text-white'>Total Deposit</th>
                                  <th scope="col" className='text-white'>Total Stacking Reward</th>
                                  <th scope="col" className='text-white text-end'>Total Referral Reward</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  !loading && referralTransactionsData?.length > 0 ? (
                                    referralTransactionsData.map((item, index) => {

                                      let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                                      return (
                                        <>
                                          <tr key={index}>
                                            {/* <td className='text-white'>{index + 1}</td>
                                            <td className='text-white'>{formattedTime}</td>
                                            <td className='text-white'>{item.amount}</td>
                                            <td className='text-white'>{item.direct_reward.rewardAmount}</td>
                                            <td className='text-white text-end'>{item.rewardAmount}</td> */}
                                            <td className='text-white'>{index + 1}</td>
                                            <td className='text-white'>{item.name}</td>
                                            <td className='text-white'>{item.stackingAmount}</td>
                                            <td className='text-white'>{item.stackingReward}</td>
                                            <td className='text-white text-end'>{item.referralReward}</td>
                                          </tr>
                                        </>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={12} className="text-center text-white">
                                        {loading ? <div className="spinner-border text-primary" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div> : "No data found"}
                                      </td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                            <div className='centerListPagination'>
                              {!loading && totalPage > 1 &&
                                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-xxl-3 col-lg-3'>
            <div className="boderm" style={{ color: '#fff' }}>
              <h5>Deposit QR Code</h5>
              <div className="card radius-10 overflow-hidden w-100 mb-4 d-flex justify-content-center">

                <div className="card-body border-m">
                  <div className="p-4 d-flex justify-content-center">
                    <img src={IMG_PREFIX + context?.userSetting?.qrImage} className="img-fluid" height={'100%'} width={'100%'} />
                  </div>
                  <div className='text-white text-center'>
                    <div className='text-wrap'>{context?.userSetting?.walletAddress} <i className="fa-regular fa-copy ms-2 text-danger" style={{ cursor: 'pointer' }} onClick={() => handleCopyWalletAddress(`${context?.userSetting?.walletAddress}`)}></i>
                    </div>
                  </div>
                </div>

              </div>
              <ul style={{ marginBottom: '30px' }}>
                <li> Deposit only Fronx token here.</li>
                <li>Minimum deposit value : {Number(context?.userSetting?.minDepositAmount).toFixed(0)} Fronx</li>
                <li>Your deposit reflect in 24 hours. If not please contact us.</li>
                <li>Deposit or withdrawal will be done only from the wallet address provided by you.</li>
              </ul>

              {/* <div style={{ marginBottom: '50px', cursor: 'pointer' }} onClick={() => handleCopyURL(`${context?.userSetting?.youtubeLink}`)} className='gallery-card'>
                  <div className='gallery-content'>
                    <a href={`${context?.userSetting?.youtubeLink}`} target='_blank'>
                      <img src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail} height={'100%'} width={'100%'} />
                    </a>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferralPage
