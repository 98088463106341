import React, { useContext, useEffect, useState } from 'react'
import { getStackingRewardAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment'
import MainContext from '../../Configs/Context/MainContext'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormGroup, Button } from 'react-bootstrap'
import Pagination from '../../Configs/Pagination/Pagination'

const IMG_PREFIX = process.env.REACT_APP_SERVER_URL;

const StackingPage = () => {

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [stackingRewardData, setStackingRewardData] = useState([]);
  const [totalStackingRewardAmount, setTotalStackingRewardAmount] = useState('');
  const context = useContext(MainContext)
  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const [prevSearch, setPrevSearch] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const getStackingReward = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getStackingRewardAPI(startDate, endDate, currentPage, limit, controller)
    if (response.status === 200) {
      setStackingRewardData(response.data?.data)
      // setTotalRecord(response.data.totalRecords);
      setTotalStackingRewardAmount(response?.data?.totalRewardAmount)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      getStackingReward()
    }
  }, [startDate, endDate])

  const handleSearch = (e) => {
    e.preventDefault();
    getStackingReward()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    getStackingReward()
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('URL copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className='page-wrapper p-3 back-page'>
      <div className='page-content'>
        <div className='row'>
          <div class="col-12 col-lg-9 col-xl-9">
            <div class="card w-100">
              <div class="card-body">
                <div>
                  <form className='d-flex flex-wrap justify-content-between gap-2 mb-3' onSubmit={handleSearch}>
                    <div className='text-white'>
                      <p>Total Stacking Reward: {totalStackingRewardAmount} Fronx</p>
                    </div>
                    <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                      <div className='dashDateSearch'>
                        <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                        <FormGroup>
                          <DateRangePicker
                            className='dateSearch'
                            onChange={handleDateChange}
                            value={dateRange}
                            showLeadingZeros
                            format="dd/MM/y"
                          />
                        </FormGroup>
                      </div>
                      <Button variant="secondary" onClick={handleResetFilter}>
                        <i class="fa-solid fa-retweet"></i>
                      </Button>
                    </div>
                  </form>
                </div>
                <div class="table-responsive">
                  <table className="table mb-0 table-modern table-hover mb-0 transaction-table">
                    <thead style={{ backgroundColor: '#0B1830' }}>
                      <tr>
                        <th scope="col" className='text-white'>No.</th>
                        <th scope="col" className='text-white'>Date</th>
                        <th scope="col" className='text-white text-end'>Staking Reward</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !loading && stackingRewardData?.length > 0 ? (
                          stackingRewardData.map((item, index) => {
                            const formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                            return (
                              <tr key={index}>
                                <td scope='col' className='text-white'>{index + 1}</td>
                                <td scope='col' className='text-white'>{formattedTime}</td>
                                <td scope='col' className='text-white  text-end'>{item.rewardAmount}</td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={12} className="text-center text-white">
                              {
                                loading ? <div className="spinner-border text-primary" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div> : "No data found"}
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                  <div className='centerListPagination'>
                    {!loading && totalPage > 1 &&
                      <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    }
                  </div>
                </div>
              </div >
            </div >
          </div >

          <div className='col-xxl-3 col-lg-3'>
            <div className="boderm" style={{ color: '#fff' }}>
              <h5>Deposit QR Code</h5>
              <div className="card radius-10 overflow-hidden w-100 mb-4 d-flex justify-content-center">

                <div className="card-body border-m">
                  <div className="p-4 d-flex justify-content-center">
                    <img src={IMG_PREFIX + context?.userSetting?.qrImage} className="img-fluid" height={'100%'} width={'100%'} />
                  </div>
                  <div className='text-white text-center'>
                    <div className='text-wrap'>{context?.userSetting?.walletAddress} <i className="fa-regular fa-copy ms-2 text-danger" style={{ cursor: 'pointer' }} onClick={() => handleCopy(`${context?.userSetting?.walletAddress}`)}></i>
                    </div>
                  </div>
                </div>

              </div>
              <ul style={{ marginBottom: '30px' }}>
                <li> Deposit only Fronx token here.</li>
                <li>Minimum deposit value : {Number(context?.userSetting?.minDepositAmount).toFixed(0)} Fronx</li>
                <li>Your deposit reflect in 24 hours. If not please contact us.</li>
                <li>Deposit or withdrawal will be done only from the wallet address provided by you.</li>
              </ul>

              {/* <div style={{ marginBottom: '50px', cursor: 'pointer' }} onClick={() => handleCopyURL(`${context?.userSetting?.youtubeLink}`)} className='gallery-card'>
                  <div className='gallery-content'>
                    <a href={`${context?.userSetting?.youtubeLink}`} target='_blank'>
                      <img src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail} height={'100%'} width={'100%'} />
                    </a>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default StackingPage
