import React, { useContext, useEffect, useState } from 'react'
import { getProfileAPI, updateProfileAPI, updatePasswordAPI, ChangeWalletAddressRequestAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form';
import { Form, FormGroup, FloatingLabel, Button, Spinner } from 'react-bootstrap'
import editBtn from '../../Assets/Images/editBtn.png'
import MainContext from '../../Configs/Context/MainContext';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-input-2'

const ProfilePage = () => {

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingWalletAddress, setLoadingWalletAddress] = useState(false);
  const [profileDetails, setProfileDetails] = useState('');
  const { register, handleSubmit, formState: { errors }, setValue, watch, control } = useForm({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [ConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState(false);
  const [walletAddressChangeStatus, setWalletAddressChangeStatus] = useState(false);
  const context = useContext(MainContext)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [phone, setPhone] = useState('');
  const [walletAddress, setWalletAddress] = useState('');

  const getProfile = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getProfileAPI(controller)
    if (response.status === 200) {
      setProfileDetails(response.data.data)
      context.setProfile(response.data?.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (profileDetails) {
      setValue('user_name', profileDetails.name)
      setValue('email', profileDetails.email)
      // setValue('phone', profileDetails.phone)
      setPhone(profileDetails.phone)
      setValue('referral_code', profileDetails.referralCode)
      setValue('wallet_address', profileDetails.walletAddress)
      setWalletAddress(profileDetails.walletAddress)
    }
  }, [profileDetails])

  useEffect(() => {
    if (profileDetails?.createdAt) {
      const formattedDate = profileDetails.createdAt.split('T')[0];
      setValue("joining_date", formattedDate);
    }
  }, [profileDetails, setValue]);

  const onSubmit = async (data) => {
    setLoading(true)
    const body = {
      name: data.user_name,
      // email: data.email,
      phone: phone,
      // password: data.password,
    }

    // const formData = new FormData()

    // formData.append('name', data.user_name)
    // formData.append('email', data.email)
    // formData.append('phone', data.phone)
    // formData.append('password', data.password)

    // for (let i = 0; i < originFileList?.length; i++) {
    //   formData.append('image', originFileList[i])
    // }

    const controller = new AbortController()
    controllers.push(controller)
    const response = await updateProfileAPI(body, controller)
    if (response.status === 200) {
      // setReferralTransactionsData(response.data?.data)
      toast.success(response.data.message)
      getProfile()
      // window.location.reload();
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault()
    setLoadingPassword(true)
    const controller = new AbortController()
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,}$/;

    controllers.push(controller)
    if (!regex.test(newPassword)) {
      toast.error('Password Must Contain At Least One Uppercase Letter, One Lowercase Letter, One Number, and One Special Character');
      setLoadingPassword(false);
    } else {

      const body = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }

      const response = await updatePasswordAPI(body, controller)
      if (response.status === 200) {
        // setReferralTransactionsData(response.data?.data)
        toast.success(response.data.message)
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setPasswordChangeStatus(false)
        getProfile()
        setLoadingPassword(false)
      } else {
        toast.error(response.response?.data.message);
        setLoadingPassword(false)
      }
    }
  }

  const handleWalletAddressChange = async (e) => {
    e.preventDefault()
    setLoadingWalletAddress(true)
    const controller = new AbortController()

    const body = {
      walletAddress: walletAddress
    }

    const response = await ChangeWalletAddressRequestAPI(body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setWalletAddress('')
      setLoadingWalletAddress(false)
      getProfile()
      setWalletAddressChangeStatus(false)
    } else {
      toast.error(response.response?.data.message);
      setLoadingWalletAddress(false)
    }
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Referral Code copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className='container'>
      <div className="page-wrapper p-3 back-page">
        <div className="page-content">
          <div className="row">
            <div className="col-md-12 text-white">
              <h1>Profile</h1>
            </div>
          </div>

          <div className="row h-100 align-content-start d-flex justify-content-center mt-3">
            <div className="col-lg-12">
              <Card className='p-3'>
                <Card.Title className='text-white p-3'><i class="fa-regular fa-user me-3"></i>Personal Information</Card.Title>
                <Card.Body>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row g-4'>
                      <div className='col-md-6'>
                        <FloatingLabel controlId="firstName" label="Username 📝" className='custom-floating-label ' style={{ backgroundColor: 'transparent', fontSize: '15px', color: 'grey' }}>
                          <Form.Control type='text'
                            {...register("user_name", { required: true })} placeholder='Enter User Name' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} />
                        </FloatingLabel>
                        <p className='Err-msg'>{errors.user_name && <p>User Name is required</p>}</p>
                      </div>

                      <div className='col-md-6'>
                        <FloatingLabel controlId="emailId" label="Email ID" className='custom-floating-label' style={{ backgroundColor: 'transparent' }}>
                          <Form.Control type='text' readOnly
                            {...register("email", {
                              required: "Email is Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please Enter Valid Email",
                              }
                            })} placeholder='Enter Email' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} />
                        </FloatingLabel>
                        <p className='Err-msg'>{errors.email ? <div>{`${errors?.email?.message}`}</div> : ""}</p>
                      </div>

                      {/* <div className='col-md-6'>
                        <FloatingLabel controlId="mobileNo" label="Mobile No." className='custom-floating-label' style={{ backgroundColor: 'transparent' }}>
                          <Form.Control type='text'
                            {...register("phone", {
                              required: "Mobile Number is Required",
                              minLength: { value: 7, message: "Mobile Number must be at least 7 digits", },
                              maxLength: { value: 15, message: "Mobile Number cannot exceed 15 digits", },
                            })} placeholder='Enter Mobile No.' className="form-control quantity-search" />
                        </FloatingLabel>
                        <p className='Err-msg'>{errors.phone && (<p className="Err-msg">{(errors.phone)?.message}</p>)}</p>
                      </div> */}

                      <div className='col-md-6'>
                        <Form.Group className="form-control quantity-search">
                          <Form.Label style={{ fontSize: '14px' }}>Phone 📝</Form.Label>
                          <PhoneInput
                            country={'in'}
                            className='profile-telephone'
                            name="phoneNumber"
                            autoFocus={true}
                            id="filled-basic"
                            variant="filled"
                            placeholder='Phone number'
                            value={phone}
                            onChange={setPhone}
                            style={{ width: '100%', color: 'grey', backgroundColor: '#141D2D' }}
                          />
                        </Form.Group>
                      </div>

                      {
                        context?.profile?.role === "user" &&
                        <>
                          <div className='col-md-6'>
                            <FloatingLabel controlId="walletAddress" label="Wallet Address" className='custom-floating-label' style={{ backgroundColor: 'transparent' }}>
                              <Form.Control type='text' readOnly
                                {...register("wallet_address", { required: true })} placeholder='Enter Wallet Address' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} />
                            </FloatingLabel>
                            <p className='Err-msg'>{errors.wallet_address && <p>Wallet Address is required</p>}</p>
                          </div>

                          {/* <div className='col-md-6'>
                            <FloatingLabel controlId="referralCode" label="Referral code" className='custom-floating-label d-flex align-items-center' style={{ backgroundColor: 'transparent' }}>
                              <Form.Control type='text' readOnly
                                {...register("referral_code", { required: true })} placeholder='Enter Referral Code' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} />
                              <div style={{ cursor: 'pointer', backgroundColor: '#80808036', padding: '5px 10px', borderRadius: '10px' }} class=" cursor-pointer ms-1" onClick={() => handleCopy(`${profileDetails.referralCode}`)}>
                                <i class="fa-regular fa-copy text-danger"></i>
                              </div>
                            </FloatingLabel>
                            <p className='Err-msg'>{errors.referral_code && <p>Referral Code is required</p>}</p>
                          </div> */}

                          {/* <div className="col-md-6 position-relative">
                            <FloatingLabel
                              controlId="referralCode"
                              label="Referral code"
                              className="custom-floating-label"
                              style={{ backgroundColor: 'transparent' }}
                            >
                              <Form.Control
                                type="text"
                                readOnly
                                {...register("referral_code", { required: true })}
                                placeholder="Enter Referral Code"
                                className="form-control quantity-search fw-bolder"
                                style={{ fontSize: '16px' }}
                              />
                              <div
                                style={{
                                  cursor: 'pointer', backgroundColor: '#80808036', padding: '5px 10px', borderRadius: '10px', position: 'absolute', right: '10px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                }}
                                onClick={() => handleCopy(`${profileDetails.referralCode}`)}
                              >
                                <i className="fa-regular fa-copy text-danger"></i>
                              </div>
                            </FloatingLabel>
                            <p className="Err-msg">
                              {errors.referral_code && <p>Referral Code is required</p>}
                            </p>
                          </div> */}

                          {/* <div className='col-md-6'>
                            <Form.Label className='text-white mb-0'>Referral Code</Form.Label>
                            <InputGroup>
                              <Form.Control type='text' readOnly
                                {...register("referral_code", { required: true })} placeholder='Enter Referral Code' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} />
                              <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                                <div style={{ cursor: 'pointer', }} class=" cursor-pointer ms-1" onClick={() => handleCopy(`${profileDetails.referralCode}`)}>
                                  <i class="fa-regular fa-copy text-danger"></i>
                                </div>
                              </InputGroup.Text>
                            </InputGroup>
                          </div> */}

                          <div className='col-md-6'>
                            <FloatingLabel controlId="joiningDate" label="Joining Date" className='custom-floating-label' style={{ backgroundColor: 'transparent' }}>
                              <Form.Control type='date'
                                {...register("joining_date", { required: true })} placeholder='Enter Joining Date' className="form-control quantity-search fw-bolder" style={{ fontSize: '16px' }} readOnly />
                            </FloatingLabel>
                            <p className='Err-msg'>{errors.joining_date && <p>Joining Date is required</p>}</p>
                          </div>
                        </>
                      }
                    </div>

                    <div className='row  mt-4 text-end'>
                      <div className=''>
                        <Button className='withdrawal-active-btn' color='success' icon='Done' type='submit' isDisable={loading} ><i class="fa-solid fa-check me-2"></i> Submit {loading && <Spinner size="sm" isSmall inButton='onlyIcon' isGrow />}</Button>
                      </div>
                    </div>

                  </form>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row h-100 align-content-start d-flex justify-content-center mt-3 password-div">
            <div className='col-lg-12'>
              <Card className='p-3'>
                <div className='row text-white d-flex justify-content-between text-white align-items-center '>
                  <div className='col-md-6'><Card.Title className='text-white p-3'><i class="fa-solid fa-lock me-3"></i>Password</Card.Title></div>
                  <div className='col-md-6 text-end'>Do you want to change?
                    {
                      passwordChangeStatus ?
                        (
                          <Button
                            color='primary'
                            variant="secondary"
                            isLight
                            className='ms-4'
                            onClick={() => setPasswordChangeStatus(!passwordChangeStatus)}
                          >
                            <i class="fa-solid fa-xmark me-2"></i>No
                          </Button>
                        ) : (
                          <Button
                            color='primary'
                            isLight
                            className='ms-4'
                            onClick={() => setPasswordChangeStatus(!passwordChangeStatus)}
                          >
                            <i class="fa-regular fa-circle-check me-2"></i>Yes
                          </Button>
                        )
                    }
                  </div>
                </div>

                {
                  passwordChangeStatus &&
                  <Card.Body>
                    <form onSubmit={handlePasswordSubmit}>
                      <div className='row g-4'>

                        {/* <div className='col-md-12'>
                          <InputGroup>
                            <Form.Control type={passwordVisible ? 'text' : 'password'}
                              {...register('password', { required: "Password is Required" })} className="form-control quantity-search" placeholder='Enter Current Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                              <i className={passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.password && <p>Password is required</p>}</p>
                        </div> */}

                        <div className='col-12'>
                          <InputGroup>
                            <Form.Control type={passwordVisible ? 'text' : 'password'} onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} className="form-control quantity-search" required placeholder='Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                              <i className={passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                        </div>

                        <div className='col-12'>
                          <InputGroup>
                            <Form.Control type={newPasswordVisible ? 'text' : 'password'} onChange={(e) => setNewPassword(e.target.value)} value={newPassword} className="form-control quantity-search" required placeholder='New Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setNewPasswordVisible(!newPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={newPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                        </div>

                        <div className='col-12'>
                          <InputGroup>
                            <Form.Control type={ConfirmPasswordVisible ? 'text' : 'password'} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className="form-control quantity-search" required placeholder='Confirm Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setConfirmPasswordVisible(!ConfirmPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={ConfirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                        </div>

                        {/* <div className='col-md-12'>
                          <InputGroup>
                            <Form.Control type={newPasswordVisible ? 'text' : 'password'}
                              {...register('new_password', {
                                required: "New Password is required",
                                validate: (value) => {
                                  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,}$/;
                                  return (
                                    regex.test(value) ||
                                    "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character"
                                  );
                                },
                              })} className="form-control quantity-search" placeholder='Enter New Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setNewPasswordVisible(!newPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={newPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.new_password ? <div>{`${errors?.new_password?.message}`}</div> : ""}</p>
                        </div> */}

                        {/* <div className='col-md-12'>
                          <InputGroup>
                            <Form.Control type={ConfirmPasswordVisible ? 'text' : 'password'}
                              {...register('confirm_password', {
                                required: "Confirm Password is required",
                                validate: (value) => {
                                  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,}$/;
                                  return (
                                    regex.test(value) ||
                                    "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character"
                                  );
                                },
                              })} className="form-control quantity-search" placeholder='Enter Confirm Password' />
                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setConfirmPasswordVisible(!ConfirmPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={ConfirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: '#fff' }} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.confirm_password ? <div>{`${errors?.confirm_password?.message}`}</div> : ""}</p>
                        </div> */}

                        <div className='row  mt-4 text-end'>
                          <div className=''>
                            <Button color='success' className='withdrawal-active-btn' icon='Done' type='submit' isDisable={loading} ><i class="fa-solid fa-check me-2"></i> Submit {loading && <Spinner size="sm" isSmall inButton='onlyIcon' isGrow />}</Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Card.Body>
                }

              </Card>
            </div>
          </div>


          {
            context?.profile?.role === "user" &&
            <div className="row h-100 align-content-start d-flex justify-content-center mt-3 mb-5">
              <div className='col-lg-12'>
                <Card className='p-3'>
                  <div className='row text-white d-flex justify-content-between text-white align-items-center'>
                    <div className='col-md-6'><Card.Title className='text-white p-3'><i class="fa-solid fa-lock me-3"></i>Wallet Address</Card.Title></div>
                    <div className='col-md-6 text-end'>Do you want to change?
                      {
                        walletAddressChangeStatus ?
                          (
                            <Button
                              color='primary'
                              variant='secondary'
                              isLight
                              className='ms-4'
                              onClick={() => setWalletAddressChangeStatus(!walletAddressChangeStatus)}
                            >
                              <i class="fa-solid fa-xmark me-2"></i>No
                            </Button>
                          ) :
                          (
                            <Button
                              color='primary'
                              isLight
                              className='ms-4'
                              onClick={() => setWalletAddressChangeStatus(!walletAddressChangeStatus)}
                            >
                              <i class="fa-regular fa-circle-check me-2"></i>Yes
                            </Button>
                          )
                      }
                    </div>
                  </div>

                  {
                    walletAddressChangeStatus &&
                    <Card.Body>
                      <form onSubmit={handleWalletAddressChange}>
                        <div className='row g-4'>

                          <div className='col-12'>
                            <InputGroup>
                              <Form.Control type='text' onChange={(e) => setWalletAddress(e.target.value)} value={walletAddress} className="form-control quantity-search" placeholder='Wallet Address' />
                            </InputGroup>
                          </div>



                        </div>
                        <div className='mt-4 row align-items-center'>
                          <div className='col-lg-10'>
                            <p className='text-white'>Your request may updated upto 24 hours, after that you have any query, you can contact admin from Contact Us page.</p>
                          </div>
                          <div className='col-lg-2'>
                            <Button color='success' className='withdrawal-active-btn' icon='Done' type='submit' isDisable={loading} ><i class="fa-solid fa-check me-2"></i> Request to Admin {loading && <Spinner size="sm" isSmall inButton='onlyIcon' isGrow />}</Button>
                          </div>
                        </div>
                      </form>
                    </Card.Body>
                  }
                </Card>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
