import React, { useEffect, useState } from 'react'
import { getAllDeActiveUserAPI, getSearchAllDeActiveUserAPI } from '../../Configs/Utils/Apis/UserApis';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button, FormGroup } from 'react-bootstrap';
import ROUTES from '../../Configs/Routes';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Pagination from '../../Configs/Pagination/Pagination'
import CustomModal from '../../Configs/CustomModal/CustomModal';
import DeleteUserModal from './UserActiveDactiveModal';

const InActiveUserList = () => {

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [allInActiveUsers, setAllInActiveUsers] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState('');
  const [totalPage, setTotalPage] = useState('');
  const [prevSearch, setPrevSearch] = useState();
  const [search, setSearch] = useState('');
  const limit = 10;
  const [deleteUserModalStatus, setDeleteUserModalStatus] = useState(false);
  const [userId, setUserId] = useState('');

  const getAllDeActiveUser = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getAllDeActiveUserAPI(currentPage, limit, controller)
    if (response.status === 200) {
      setAllInActiveUsers(response.data?.data)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!search) {
      getAllDeActiveUser()
    }
  }, [search, currentPage, limit])

  const userSearch = async () => {
    setLoading(true)
    const controller = new AbortController();
    const response = await getSearchAllDeActiveUserAPI(search, currentPage, limit, controller);
    if (response.status === 200) {
      setAllInActiveUsers(response.data.data)
      setTotalRecord(response.data.totalRecords)
      setLoading(false)
      setPrevSearch('')
    }
    else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (search) {
      userSearch()
    }
  }, [currentPage, limit])

  const handleSearch = (e) => {
    e.preventDefault();
    const hasChanged =
      prevSearch?.search !== search;

    if (hasChanged && (search)) {
      userSearch()
      setCurrentPage(1);
      setPrevSearch({ search });
    }
  }

  const handleResetFilter = () => {
    setSearch('')
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Wallet Address copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className='page-wrapper p-3 back-page'>
      <div className='page-content'>
        <div className='card w-100 mb-3'>
          <div className='card-body'>
            <div>
              <form className='searchMain gap-2 mb-3' onSubmit={handleSearch}>
                <FormGroup>
                  <Form.Control className="search-input text-white" value={search} type='text' placeholder='Name/Wallet Address' onChange={(e) => setSearch(e.target.value)} />
                </FormGroup>

                <Button type='submit' variant="secondary">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </Button>
                <Button variant="secondary" onClick={handleResetFilter}>
                  <i class="fa-solid fa-retweet"></i>
                </Button>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-modern table-hover mb-0 transaction-table">
                <thead style={{ backgroundColor: '#0B1830' }}>
                  <tr>
                    <th scope="col" className='text-white'>No</th>
                    <th scope="col" className='text-white'>Username</th>
                    <th scope="col" className='text-white'>Email</th>
                    <th scope="col" className='text-white'>WalletAddress</th>
                    <th scope="col" className='text-white'>Status</th>
                    <th scope="col" className='text-white'>Phone</th>
                    {/* <th scope="col" className='text-white'>Status</th> */}
                    <th scope="col" className='text-white'>Joining Date</th>
                    <th scope="col" className='text-white text-end'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading && allInActiveUsers.length > 0 ? (
                      allInActiveUsers.map((item, index) => {
                        const formattedDate = moment(item.createdAt).format('DD/MM/YYYY')
                        return (
                          <>
                            <tr key={index} className='de-active-user-list'>
                              <td className='text-white'>{index + 1}</td>
                              <td className='text-white'>{item.name}</td>
                              <td className='text-white'>{item.email}</td>
                              <td className='text-white'>
                                <div className='d-flex justify-content-center'>
                                  <div>{item.walletAddress}</div>
                                  <div style={{ cursor: 'pointer' }} class=" cursor-pointer ms-2" onClick={() => handleCopy(`${item.walletAddress}`)}><i class="fa-regular fa-copy text-danger"></i></div>
                                </div>
                              </td>
                              <td scope='col' className={`userStatus${item.status}`}>{item.status === "inactive" ? 'in-active' : 'active'}</td>
                              <td className='text-white'>{item.phone === '' ? '-' : item.phone}</td>
                              <td className='text-white'>{formattedDate}</td>
                              {/* <td className='text-center' style={{ minWidth: '10px' }}>
                                <div className={`userStatus ${item.status}`}>
                                  {item.status === "active" ? <span>Active</span> : <span>In-active</span>}
                                </div>
                              </td> */}
                              <td className='text-end'>
                                <Link to={ROUTES.USER_LIST.LIST + item.id}>
                                  <Button>
                                    <i class="fa-regular fa-eye text-white"></i>
                                  </Button>
                                </Link>
                                <Button variant='danger' className='ms-2' onClick={() => { setDeleteUserModalStatus(true); setUserId(item) }}>
                                  <i class="fa-solid fa-ban"></i>
                                </Button>
                              </td>
                            </tr >
                          </>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center text-white">
                          {loading ?
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            : "No data found"}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
            <div className='centerListPagination mt-2'>
              {!loading && totalPage > 1 &&
                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              }
            </div>
          </div>
        </div>
      </div>

      <CustomModal display={deleteUserModalStatus} handleClose={() => setDeleteUserModalStatus(!deleteUserModalStatus)} size='md' className='userModal doctor-slot' top backdrop="static">
        <DeleteUserModal
          setDeleteUserModalStatus={setDeleteUserModalStatus}
          userId={userId}
          getAllUser={getAllDeActiveUser}
        />
      </CustomModal>

    </div>
  )
}

export default InActiveUserList