import React, { useContext, useEffect, useState } from 'react'
import { AdminWithdrawalTransactionAPI, getUserSettingAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment'
import MainContext from '../../Configs/Context/MainContext'
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button, InputGroup, FormGroup } from 'react-bootstrap'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Pagination from '../../Configs/Pagination/Pagination'
import { Link } from 'react-router-dom'
import CustomModal from '../../Configs/CustomModal/CustomModal'
import WithdrawalStatusChangeModal from './WithdrawalStatusChangeModal'
import angleDownSolid from '../../Assets/Images/sm_angle.svg'
import ROUTES from '../../Configs/Routes'

const AdminWithdrawal = () => {

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [adminWithdrawalTransactionsData, setAdminWithdrawalTransactionsData] = useState([]);
  const [prevSearch, setPrevSearch] = useState();
  const [totalRecord, setTotalRecord] = useState();
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [depositID, setDepositID] = useState();
  const [userSetting, setUserSetting] = useState();
  const [status, setStatus] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const getUserSetting = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getUserSettingAPI(controller)
    if (response.status === 200) {
      setUserSetting(response.data?.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserSetting()
  }, [])


  const AdminWithdrawalTransaction = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await AdminWithdrawalTransactionAPI(startDate, endDate, status, currentPage, limit, controller)
    if (response.status === 200) {
      setAdminWithdrawalTransactionsData(response.data?.data)
      setLoading(false)
      setTotalRecord(response.data.totalRecords);
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!startDate || !endDate || !status) {
      AdminWithdrawalTransaction()
    }
  }, [startDate, endDate, status, currentPage, limit])

  useEffect(() => {
    if (startDate && endDate && status) {
      AdminWithdrawalTransaction()
    }
  }, [currentPage, limit, startDate, endDate, status])

  const handleSearch = (e) => {
    e.preventDefault();
    AdminWithdrawalTransaction()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate, status });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    AdminWithdrawalTransaction()
    setStatus('');
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('Wallet Address copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <div className="page-wrapper p-3 back-page">
      <div className="page-content">
        <div className='row'>
          <div className='col-12 mb-4 mb-3'>
            <div>
              <form className='d-flex flex-wrap justify-content-between align-items-center gap-2 mb-3' onSubmit={handleSearch}>
                <div className='d-flex justify-content-start text-white flex-column align-items-end'>
                  <span>min withdrawal amount : {userSetting?.minWithdrawableAmount} fronx</span>
                  {/* <span>max withdrawal amount : {userSetting?.maxWithdrawableAmount} fronx</span> */}
                </div>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div>
                    <FormGroup>
                      <Form.Select style={{
                        backgroundColor: 'black',
                        color: 'white',
                        '--bs-form-select-bg-img': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='white' d='M2 5L0 0h4z'/%3E%3C/svg%3E")`,
                        '--bs-form-select-bg-size': '10px',
                      }} className='status-select' onChange={(e) => setStatus(e.target.value)} value={status}>
                        <option value=''>Select Status</option>
                        <option value='0'>Pending</option>
                        <option value='1'>Complete</option>
                        <option value='2'>Reject</option>
                      </Form.Select>
                    </FormGroup>
                  </div>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff', '--bs-form-select-bg-img': `url(${angleDownSolid})` }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleDateChange}
                        value={dateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  {/* <Button type='submit' variant="secondary">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </Button> */}
                  <Button variant="secondary" onClick={handleResetFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table mb-0 table-modern table-hover mb-0 transaction-table">
                <thead style={{ backgroundColor: '#0B1830' }}>
                  <tr>
                    <th scope="col" className='text-white'>No.</th>
                    <th scope="col" className='text-white'>Name</th>
                    <th scope="col" className='text-white'>Wallet Address</th>
                    <th scope="col" className='text-white '>Amount</th>
                    <th scope="col" className='text-white'>Status</th>
                    <th scope="col" className='text-white'>Status Change</th>
                    <th scope="col" className='text-white'>Date</th>
                    <th scope="col" className='text-white text-end'>Approved Date</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && adminWithdrawalTransactionsData?.length > 0 ? (
                    adminWithdrawalTransactionsData.map((item, index) => {
                      let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                      let approvedDate = item.approvedOrRejectedAt ? moment(item.approvedOrRejectedAt).format('DD/MM/YYYY') : '-'
                      let status;
                      if (item.status === 0) {
                        status = 'Pending'
                      } else if (item.status === 1) {
                        status = 'Complete'
                      } else if (item.status === 2) {
                        status = "Reject"
                      }
                      return (
                        <>
                          <tr key={index}>
                            <td className='text-white'>{index + 1}</td>
                            <td className='text-white'>
                              <Link className='text-decoration-none fw-bolder' to={ROUTES.USER_LIST.LIST + item.userId}>{item.user.name}</Link>
                            </td>
                            <td className='text-white' >
                              <div className='d-flex justify-content-center'>
                                <div>{item?.user?.walletAddress}</div>
                                <div style={{ cursor: 'pointer' }} class=" cursor-pointer ms-2 " onClick={() => handleCopy(`${item?.user?.walletAddress}`)}><i class="fa-regular fa-copy text-danger"></i></div>
                              </div>
                            </td>
                            <td className='text-white'>{item.amount}</td>
                            <td className={`text-white text-capitalize appointment ${status}`}>{status}</td>
                            <td>
                              <Button onClick={() => { setStatusChangeModal(true); setDepositID(item.id) }}>
                                <Link>
                                  <i class="fa-solid fa-arrow-rotate-right text-white"></i>
                                </Link>
                              </Button>
                            </td>
                            <td className='text-white'>{formattedTime}</td>
                            <td className='text-white text-end'>{approvedDate}</td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {loading ? <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> : "No data found"}
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
            <div className='centerListPagination mt-1'>
              {!loading && totalPage > 1 &&
                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              }
            </div>
          </div>
        </div>
      </div >

      <CustomModal display={statusChangeModal} handleClose={() => setStatusChangeModal(!statusChangeModal)} size='md' className='userModal doctor-slot' top backdrop="static">
        <WithdrawalStatusChangeModal
          setStatusChangeModal={setStatusChangeModal}
          id={depositID}
          getWithdrawalUserWise={AdminWithdrawalTransaction}
        />
      </CustomModal>
    </div >
  )
}

export default AdminWithdrawal
