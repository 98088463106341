import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getOneUserAPI, getWithdrawalUserWiseAPI, getDepositUserWiseAPI, AdminReferralDetailsAPI, getUserWiseWalletAddressAPI, getAdminStackingRewardAPI, getSettingAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { Button, FormGroup } from 'react-bootstrap';
import CustomModal from '../../Configs/CustomModal/CustomModal';
import AddAmountModal from './AddAmountModal';
import WithdrawalStatusChangeModal from './WithdrawalStatusChangeModal';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Pagination from '../../Configs/Pagination/Pagination';
import WalletAddressStatusChangeModal from './WalletAddressStatusChangeModal';
import DeleteDepositModal from './DeleteDepositModal';
import MainContext from '../../Configs/Context/MainContext';

const ViewUser = () => {

  const { id } = useParams()
  const controllers = []
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const [userWithdrawalData, setUserWithdrawalData] = useState();
  const [userDepositData, setUserDepositData] = useState([]);
  const [referralDetailsData, setReferralDetailsData] = useState([]);
  const [walletAddressRequestData, setWalletAddressRequestData] = useState([]);
  const [stackingRewardData, setStackingRewardData] = useState([]);
  const [userWithdrawalLoading, setUserWithdrawalLoading] = useState(false);
  const [userDepositLoading, setUserDepositLoading] = useState(false);
  const [referralDetailsLoading, setReferralDetailsLoading] = useState(false);
  const [walletAddressLoading, setWalletAddressLoading] = useState(false);
  const [stackingRewardLoading, setStackingRewardLoading] = useState(false);
  const [addAmountModal, setAddAmountModal] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [depositID, setDepositID] = useState();
  const [walletAddressStatusChangeModal, setWalletAddressStatusChangeModal] = useState(false);
  const [walletAddressId, setWalletAddressId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [referralTotalRecord, setReferralTotalRecord] = useState();
  const [withdrawalTotalRecord, setWithdrawalTotalRecord] = useState();
  const [depositTotalRecord, setDepositTotalRecord] = useState();
  const [referralTotalPage, setReferralTotalPage] = useState('');
  const [WithdrawalTotalPage, setWithdrawalTotalPage] = useState('');
  const [depositTotalPage, setDepositTotalPage] = useState('');
  const [withdrawalCurrentPage, setWithdrawalCurrentPage] = useState(1);
  const [depositCurrentPage, setDepositCurrentPage] = useState(1);
  const [deleteDepositModalStatus, setDeleteDepositModalStatus] = useState(false);
  const context = useContext(MainContext)

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Referral Date Start
  const [referralStartDate, setReferralStartDate] = useState(formatDate(getStartDate()));
  const [referralEndDate, setReferralEndDate] = useState(formatDate(getEndDate()));
  const [referralDateRange, setReferralDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  // Withdrawal Date Start
  const [withdrawalStartDate, setWithdrawalStartDate] = useState(formatDate(getStartDate()));
  const [withdrawalEndDate, setWithdrawalEndDate] = useState(formatDate(getEndDate()));
  const [withdrawalDateRange, setWithdrawalDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  // Deposit Date Start
  const [depositStartDate, setDepositStartDate] = useState(formatDate(getStartDate()));
  const [depositEndDate, setDepositEndDate] = useState(formatDate(getEndDate()));
  const [depositDateRange, setDepositDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  // Stacking Reward Date Start
  const [stackingRewardStartDate, setStackingRewardStartDate] = useState(formatDate(getStartDate()));
  const [stackingRewardEndDate, setStackingRewardEndDate] = useState(formatDate(getEndDate()));
  const [stackingRewardDateRange, setStackingRewardDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);

  const handleReferralDateChange = (e) => {
    setReferralStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setReferralEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setReferralDateRange([e[0], e[1]])
  }

  const handleWithdrawalDateChange = (e) => {
    setWithdrawalStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setWithdrawalEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setWithdrawalDateRange([e[0], e[1]])
  }

  const handleDepositDateChange = (e) => {
    setDepositStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setDepositEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDepositDateRange([e[0], e[1]])
  }

  const handleStackingRewardDateChange = (e) => {
    setStackingRewardStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setStackingRewardEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setStackingRewardDateRange([e[0], e[1]])
  }

  const getOneUser = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getOneUserAPI(id, controller)
    if (response.status === 200) {
      setUserData(response.data?.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getOneUser()
  }, [])

  const getSetting = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getSettingAPI(controller)
    if (response.status === 200) {
      context.setUserSetting(response.data?.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getSetting()
  }, [])

  // Admin Deposit Details Start
  const getDepositUserWise = async () => {
    setUserDepositLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getDepositUserWiseAPI(id, depositStartDate, depositEndDate, depositCurrentPage, limit, controller)
    if (response.status === 200) {
      setUserDepositData(response.data?.data)
      setDepositTotalRecord(response.data.totalRecords);
      setUserDepositLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setUserDepositLoading(false)
    }
  }

  useEffect(() => {
    if (depositStartDate && depositEndDate) {
      getDepositUserWise()
    }
  }, [depositStartDate, depositEndDate, depositCurrentPage, limit])

  const handleResetDepositFilter = () => {
    setDepositStartDate(formatDate(getStartDate()))
    setDepositEndDate(formatDate(getEndDate()))
    setDepositDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    getDepositUserWise()
  }
  // Admin Deposit Details End

  // Admin Withdrawal Details Start
  const getWithdrawalUserWise = async () => {
    setUserWithdrawalLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getWithdrawalUserWiseAPI(id, withdrawalStartDate, withdrawalEndDate, withdrawalCurrentPage, limit, controller)
    if (response.status === 200) {
      setUserWithdrawalData(response.data?.data)
      setWithdrawalTotalRecord(response.data.totalRecords);
      setUserWithdrawalLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setUserWithdrawalLoading(false)
    }
  }

  useEffect(() => {
    if (withdrawalStartDate && withdrawalEndDate) {
      getWithdrawalUserWise()
    }
  }, [withdrawalStartDate, withdrawalEndDate, withdrawalCurrentPage, limit])

  const handleResetWithdrawalFilter = () => {
    setWithdrawalStartDate(formatDate(getStartDate()))
    setWithdrawalEndDate(formatDate(getEndDate()))
    setWithdrawalDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
  }
  // Admin Withdrawal Details End

  // Admin Referral Details Start
  const AdminReferralDetails = async () => {
    setReferralDetailsLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await AdminReferralDetailsAPI(id, referralStartDate, referralEndDate, currentPage, limit, controller)
    if (response.status === 200) {
      setReferralDetailsData(response.data?.data)
      setReferralTotalRecord(response.data.totalRecords);
      setReferralDetailsLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setReferralDetailsLoading(false)
    }
  }

  useEffect(() => {
    if (referralStartDate && referralEndDate) {
      AdminReferralDetails()
    }
  }, [referralStartDate, referralEndDate, currentPage, limit])

  const handleResetReferralFilter = () => {
    setReferralStartDate(formatDate(getStartDate()))
    setReferralEndDate(formatDate(getEndDate()))
    setReferralDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    AdminReferralDetails()
  }
  // Admin Referral Details End

  // Wallet Address Listing Start
  const getUserWiseWalletAddress = async () => {
    setWalletAddressLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getUserWiseWalletAddressAPI(id, controller)
    if (response.status === 200) {
      setWalletAddressRequestData(response.data.data)
      setWalletAddressLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setWalletAddressLoading(false)
    }
  }

  useEffect(() => {
    getUserWiseWalletAddress()
  }, [])
  // Wallet Address Listing End

  // Admin Stacking Reward Start
  const getAdminStackingReward = async () => {
    setStackingRewardLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getAdminStackingRewardAPI(id, stackingRewardStartDate, stackingRewardEndDate, currentPage, limit, controller)
    if (response.status === 200) {
      setStackingRewardData(response.data.data)
      setStackingRewardLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setStackingRewardLoading(false)
    }
  }

  // useEffect(() => {
  //   getAdminStackingReward()
  // }, [])

  useEffect(() => {
    if (!stackingRewardStartDate || !stackingRewardEndDate) {
      getAdminStackingReward()
    }
  }, [stackingRewardStartDate, stackingRewardEndDate, currentPage, limit])

  useEffect(() => {
    if (stackingRewardStartDate && stackingRewardEndDate) {
      getAdminStackingReward()
    }
  }, [currentPage, limit, stackingRewardStartDate, stackingRewardEndDate])

  const handleSearchStackingReward = (e) => {
    e.preventDefault();
    getAdminStackingReward()
    setCurrentPage(1);
    // setPrevSearch({ stackingRewardStartDate, stackingRewardEndDate });
  }
  // Admin Stacking Reward End

  useEffect(() => {
    if (referralTotalRecord || referralTotalRecord === 0) {
      var page = referralTotalRecord / limit;
      setReferralTotalPage(page);
    }
  }, [referralTotalRecord]);

  useEffect(() => {
    if (withdrawalTotalRecord || withdrawalTotalRecord === 0) {
      var page = withdrawalTotalRecord / limit;
      setWithdrawalTotalPage(page);
    }
  }, [withdrawalTotalRecord]);

  useEffect(() => {
    if (depositTotalRecord || depositTotalRecord === 0) {
      var page = depositTotalRecord / limit;
      setDepositTotalPage(page);
    }
  }, [depositTotalRecord]);

  return (
    <div className='page-wrapper p-3 dashboard-main w-100'>
      <div className='page-content'>
        {/* User Personal Information Start */}
        <Card className='text-white'>
          <Card.Header className='fw-bolder'><i class="fa-regular fa-user me-2"></i> User Personal Information</Card.Header>
          <hr style={{ margin: '0' }} />
          <Card.Body>
            <div className='row'>
              <div className='col-md-6 table-responsive'>
                <table className="table fs-6 fw-semibold user-details-table gx-1">
                  <tbody>
                    <tr>
                      <td className='text-white'> Name :</td>
                      <td className='text-white'>{userData.name}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Email :</td>
                      <td className='text-white'>{userData.email}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Phone :</td>
                      <td className='text-white'>{userData.phone}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Refferal Code :</td>
                      <td className='text-white'>{userData.referralCode}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Status :</td>
                      <td className='text-white'>{userData.status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-md-6 table-responsive'>
                <table className="table fs-6 fw-semibold user-details-table gx-1">
                  <tbody>
                    <tr>
                      <td className='text-white'>Wallet Address :</td>
                      <td className='text-white'>{userData.walletAddress}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Wallet :</td>
                      <td className='text-white'>{userData.wallet}</td>
                    </tr>
                    <tr>
                      <td className='text-white'>Created Date :</td>
                      <td className='text-white'>{moment(userData.createdAt).format('DD/MM/YYYY')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* User Personal Information End */}

        {/* Change Wallet Address Start */}
        <Card className='text-white mt-3'>
          <Card.Header className='fw-bolder'>
            <i class="fa-sharp-duotone fa-solid fa-pen-to-square fa-fw me-2"></i>Wallet Address Request Information
          </Card.Header>
          {/* <div>
            <form className='searchMain gap-2 mb-3'>
              <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                <div className='dashDateSearch'>
                  <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                  <FormGroup>
                    <DateRangePicker
                      className='dateSearch'
                      onChange={handleWalletAddressDateChange}
                      value={walletAddressDateRange}
                      showLeadingZeros
                      format="dd/MM/y"
                    />
                  </FormGroup>
                </div>
                <Button variant="secondary" className='text-end' onClick={handleResetWalletAddressFilter}>
                  <i class="fa-solid fa-retweet"></i>
                </Button>
              </div>
            </form>
          </div> */}
          <Card.Body className="table-responsive">
            <table className="table table-modern table-hover mb-0 transaction-table">
              <thead style={{ backgroundColor: '#0B1830' }}>
                <tr>
                  <th scope="col" className='text-white'>No.</th>
                  <th scope="col" className='text-white'>Name</th>
                  <th scope="col" className='text-white'>Old Wallet Address</th>
                  <th scope="col" className='text-white'>New Wallet Address</th>
                  {/* <th scope="col" className='text-white'>Status Change</th> */}
                  <th scope="col" className='text-white'>Status</th>
                  <th scope="col" className='text-white'>Status Change</th>
                  <th scope="col" className='text-white text-end'>Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  !walletAddressLoading && walletAddressRequestData.length > 0 ? (
                    walletAddressRequestData.map((item, index) => {
                      const formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                      let status;
                      if (item.status === 0) {
                        status = 'Pending'
                      } else if (item.status === 1) {
                        status = 'Complete'
                      } else if (item.status === 2) {
                        status = "Reject"
                      }
                      return (
                        <>
                          <tr>
                            <td className='text-white'>{index + 1}</td>
                            <td className='text-white'>{item?.user?.name}</td>
                            <td className='text-white'>{item?.oldWalletAddress}</td>
                            <td className='text-white'>{item?.newWalletAddress}</td>
                            <td className={`text-white text-capitalize appointment ${status}`}>{status}</td>
                            <td>
                              <Button onClick={() => { setWalletAddressStatusChangeModal(true); setWalletAddressId(item.id) }}>
                                <Link>
                                  <i class="fa-solid fa-arrow-rotate-right text-white"></i>
                                </Link>
                              </Button>
                            </td>
                            <td className='text-white text-end'>{formattedTime}</td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {userWithdrawalLoading ?
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          : "No data found"}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </Card.Body>
          {/* <Card.Body></Card.Body> */}
        </Card>
        {/* Change Wallet Address End */}

        {/* user Add Wallet Start */}
        <Card className='text-white mt-3'>
          <Card.Header className='fw-bolder'><i class="fa-solid fa-wallet me-2"></i>Add Amount</Card.Header>
          <hr style={{ margin: '0' }} />
          <Card.Body>
            <Button className='withdrawal-active-btn' onClick={() => setAddAmountModal(true)}>Add Amount</Button>
          </Card.Body>
        </Card>
        {/* user Add Wallet End */}

        {/* User Withdrawal List Start */}
        <Card className='mt-3'>
          <div className='py-2'>
            <div className='d-flex justify-content-between align-items-center date-title'>
              <Card.Header className='fw-bolder text-white'><i class="fa-solid fa-indian-rupee-sign me-2"></i> User Withdrawal Request Information</Card.Header>
              <div>
                <form className='searchMain gap-2 mb-3'>
                  <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                    <div className='dashDateSearch'>
                      <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                      <FormGroup>
                        <DateRangePicker
                          className='dateSearch'
                          onChange={handleWithdrawalDateChange}
                          value={withdrawalDateRange}
                          showLeadingZeros
                          format="dd/MM/y"
                        />
                      </FormGroup>
                    </div>
                    {/* <Button type='submit' variant="secondary">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </Button> */}
                    <Button variant="secondary" className='text-end' onClick={handleResetWithdrawalFilter}>
                      <i class="fa-solid fa-retweet"></i>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <Card.Body className="table-responsive">
            <table className="table table-modern table-hover mb-0 transaction-table">
              <thead style={{ backgroundColor: '#0B1830' }}>
                <tr>
                  <th scope="col" className='text-white'>No.</th>
                  <th scope="col" className='text-white'>Amount</th>
                  <th scope="col" className='text-white'>Status</th>
                  <th scope="col" className='text-white'>Status Change</th>
                  <th scope="col" className='text-white '>Date</th>
                  <th scope="col" className='text-white text-end'>Approved Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  !userWithdrawalLoading && userWithdrawalData?.length > 0 ? (
                    userWithdrawalData.map((item, index) => {
                      let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                      let approvedTime = item.approvedOrRejectedAt ? moment(item.approvedOrRejectedAt).format('DD/MM/YYYY') : '-'
                      let status;
                      if (item.status === 0) {
                        status = 'Pending'
                      } else if (item.status === 1) {
                        status = 'Complete'
                      } else if (item.status === 2) {
                        status = "Reject"
                      }
                      return (
                        <>
                          <tr key={index}>
                            <td className='text-white'>{index + 1}</td>
                            <td className='text-white'>{item.amount}</td>
                            <td className={`text-white text-capitalize appointment ${status}`}>{status}</td>
                            <td>
                              <Button onClick={() => { setStatusChangeModal(true); setDepositID(item.id) }}>
                                <Link>
                                  <i class="fa-solid fa-arrow-rotate-right text-white"></i>
                                </Link>
                              </Button>
                            </td>
                            <td className='text-white'>{formattedTime}</td>
                            <td className='text-white text-end'>{approvedTime}</td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {userWithdrawalLoading ?
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          : "No data found"}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div className='centerListPagination'>
              {
                !userWithdrawalLoading && WithdrawalTotalPage > 1 &&
                <Pagination totalPage={WithdrawalTotalPage} currentPage={withdrawalCurrentPage} setCurrentPage={setWithdrawalCurrentPage} />
              }
            </div>
          </Card.Body>
        </Card>
        {/* User Withdrawal List End */}

        {/* User deposit List Start */}
        <Card className='mt-3'>
          <div className='py-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <Card.Header className='fw-bolder text-white'><i class="fa-solid fa-indian-rupee-sign me-2"></i> User Deposit Information</Card.Header>
              <form className='searchMain gap-2 mb-3'>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleDepositDateChange}
                        value={depositDateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  {/* <Button type='submit' variant="secondary">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </Button> */}
                  <Button variant="secondary" onClick={handleResetDepositFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <Card.Body className="table-responsive">
            <table className="table table-modern table-hover mb-0 transaction-table">
              <thead style={{ backgroundColor: '#0B1830' }}>
                <tr>
                  <th scope="col" className='text-white'>No.</th>
                  <th scope="col" className='text-white'>Amount</th>
                  {/* <th scope="col" className='text-white'>Withdrawable</th> */}
                  <th scope="col" className='text-white'>Deposit Date</th>
                  <th scope="col" className='text-white'>Unlock Date</th>
                  <th scope="col" className='text-white text-end'>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  !userDepositLoading && userDepositData?.length > 0 ? (
                    userDepositData.map((item, index) => {
                      let formattedTime = moment(item.lockingDate).format('DD/MM/YYYY')
                      let depositTime = item.depositDate ? moment(item.depositDate).format('DD/MM/YYYY') : '-'
                      return (
                        <>
                          <tr key={index}>
                            <td className='text-white'>{index + 1}</td>
                            <td className='text-white'>{item.amount}</td>
                            {/* <td className='text-white'>{item.isWithdrawable === "true" ? "Yes" : 'No'}</td> */}
                            <td className='text-white'>{depositTime}</td>
                            <td className='text-white'>{formattedTime}</td>
                            <td className='text-end'>
                              <Button variant='danger' onClick={() => { setDeleteDepositModalStatus(true); setDepositID(item.id) }}>
                                <i className='fa-duotone fa-solid fa-trash fa-fw' />
                              </Button>
                            </td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {userDepositLoading ?
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          : "No data found"}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div className='centerListPagination'>
              {
                !userDepositLoading && depositTotalPage > 1 &&
                <Pagination totalPage={depositTotalPage} currentPage={depositCurrentPage} setCurrentPage={setDepositCurrentPage} />
              }
            </div>
          </Card.Body>
        </Card>
        {/* User deposit List End */}

        {/* All Referral Details Start */}
        <Card className='mt-3'>
          <div className='py-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <Card.Header className='fw-bolder text-white'><i class="fa-solid fa-indian-rupee-sign me-2"></i> Referral Details
              </Card.Header>
              <form className='searchMain gap-2 mb-3'>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleReferralDateChange}
                        value={referralDateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  <Button variant="secondary" onClick={handleResetReferralFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <Card.Body className="table-responsive">
            <table className="table table-modern table-hover mb-0 transaction-table">
              <thead style={{ backgroundColor: '#0B1830' }}>
                <tr>
                  <th scope="col" className='text-white'>No.</th>
                  <th scope="col" className='text-white'>Amount</th>
                  <th scope="col" className='text-white'>Referral Amount</th>
                  <th scope="col" className='text-white'>Type</th>
                  <th scope="col" className='text-white text-end'>Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  !referralDetailsLoading && referralDetailsData.length > 0 ? (
                    referralDetailsData.map((item, index) => {
                      let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                      return (
                        <>
                          <tr key={index}>
                            <td scope='col' className='text-white'>{index + 1}</td>
                            <td scope='col' className='text-white'>{item.amount}</td>
                            <td scope='col' className='text-white'>{item.rewardAmount}</td>
                            <td scope='col' className='text-white'>{item.name === "Referral_Reward" ? 'Referral Reward' : 'Direct Reward'}</td>
                            <td className='text-white text-end' scope='col'>{formattedTime}</td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {userDepositLoading ?
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          : "No data found"}
                      </td>
                    </tr>
                  )
                }
              </tbody>
              <div className='centerListPagination'>
                {!loading && referralTotalPage > 1 &&
                  <Pagination referralTotalPage={referralTotalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
              </div>
            </table>
          </Card.Body>
        </Card>
        {/* All Referral Details End */}

        {/* Stacking Reward Start */}
        <Card className='mt-3 mb-5'>
          <div className='py-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <Card.Header className='fw-bolder text-white'><i class="fa-solid fa-indian-rupee-sign me-2"></i> Stacking Reward Details
              </Card.Header>
              <form className='searchMain gap-2 mb-3' onSubmit={handleSearchStackingReward}>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleStackingRewardDateChange}
                        value={stackingRewardDateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  <Button variant="secondary" onClick={handleResetReferralFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <Card.Body>
            <table className="table table-modern table-hover mb-0 transaction-table">
              <thead style={{ backgroundColor: '#0B1830' }}>
                <tr>
                  <th scope="col" className='text-white'>No.</th>
                  <th scope="col" className='text-white'>Amount</th>
                  <th scope="col" className='text-white'>Stacking Amount</th>
                  <th scope="col" className='text-white text-end'>Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  !stackingRewardLoading && stackingRewardData.length > 0 ? (
                    stackingRewardData.map((item, index) => {
                      let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                      return (
                        <>
                          <tr key={index}>
                            <td scope='col' className='text-white'>{index + 1}</td>
                            <td scope='col' className='text-white'>{item.amount}</td>
                            <td scope='col' className='text-white'>{item.rewardAmount}</td>
                            <td scope='col' className='text-white text-end'>{formattedTime}</td>
                          </tr>
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center text-white">
                        {
                          loading ? <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div> : "No data found"}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </Card.Body >
        </Card >
        {/* Stacking Reward End */}

      </div >

      <CustomModal display={addAmountModal} handleClose={() => setAddAmountModal(!addAmountModal)} size='md' className='userModal doctor-slot' top backdrop="static">
        <AddAmountModal
          setAddAmountModal={setAddAmountModal}
          id={id}
          getDepositUserWise={getDepositUserWise}
          getAdminStackingReward={getAdminStackingReward}
          AdminReferralDetails={AdminReferralDetails}
          getOneUser={getOneUser}
        />
      </CustomModal>

      <CustomModal display={statusChangeModal} handleClose={() => setStatusChangeModal(!statusChangeModal)} size='md' className='userModal doctor-slot' top backdrop="static">
        <WithdrawalStatusChangeModal
          setStatusChangeModal={setStatusChangeModal}
          id={depositID}
          getWithdrawalUserWise={getWithdrawalUserWise}
        />
      </CustomModal>

      <CustomModal display={walletAddressStatusChangeModal} handleClose={() => setWalletAddressStatusChangeModal(!walletAddressStatusChangeModal)} size='md' className='userModal doctor-slot' top backdrop="static">
        <WalletAddressStatusChangeModal
          setWalletAddressStatusChangeModal={setWalletAddressStatusChangeModal}
          id={walletAddressId}
          getOneUser={getOneUser}
          getUserWiseWalletAddress={getUserWiseWalletAddress}
        />
      </CustomModal>

      <CustomModal display={deleteDepositModalStatus} handleClose={() => setDeleteDepositModalStatus(!deleteDepositModalStatus)} size='md' className='userModal doctor-slot' top backdrop="static">
        <DeleteDepositModal
          setDeleteDepositModalStatus={setDeleteDepositModalStatus}
          depositId={depositID}
          AdminDepositTransaction={getDepositUserWise}
        />
      </CustomModal>

    </div >
  )
}

export default ViewUser